import kunbiBanner from './kunbi-banner.jpg';
import KunbiHostelNimatranPatrak1 from './Kunbi-Hostel-Nimatran-patrak-1.jpg';
import KunbiHostelNimatranPatrak2 from './Kunbi-Hostel-Nimatran-patrak-2.jpg';
import KunbiHostelNimatranPatrak3 from './Kunbi-Hostel-Nimatran-patrak-3.jpg';
import KunbiHostelNimatranPatrak4 from './Kunbi-Hostel-Nimatran-patrak-4.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={kunbiBanner} className="0App-logo" alt="banner" />
        <img src={KunbiHostelNimatranPatrak1} alt="patrak 1" />
        <img src={KunbiHostelNimatranPatrak2} alt="patrak 2" />
        <img src={KunbiHostelNimatranPatrak3} alt="patrak 3" />
        <img src={KunbiHostelNimatranPatrak4} alt="patrak 4" />
      </header>
    </div>
  );
}

export default App;
